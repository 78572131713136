import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  a:{
    fontSize: '13px !important'
  },
  appBar: {
    padding: '0',
    borderRadius: 15,
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center', // align items in the center vertically
    boxShadow: 'none',  // you wrote '0 0 0' which is also valid but 'none' is more common
    backgroundColor: '#f8f7f4',
  },
  toolbar: {
   
    display: 'flex',
    justifyContent: 'center',
    width: '100%', 
    borderBottom:'solid 1px #d6d6d6', 
    alignItems: 'baseline',// Ensuring toolbar takes full width
  },
  toolbarfirst:{
    display: 'flex',
    justifyContent: 'space-between',
    width:'100%',
    alignItems: 'end'
  },
  searchBarMobile:{
    width:'100px'
  },
  leftContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    width:'37%',
    
   
  },
  brandContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    alignItems: 'center'
 
  },
  centerLogo: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1, // Align vertically
    // Adjust the width if necessary
  },
  centerLogoMobile:{
    display: 'flex',
    
    flex: 1,
  },
  mobileLogo: {
    width:'40px'
    // Offset by half the width of the logo
  },
  heading: {
    color: 'rgba(0,0,0)',
    fontSize: '16px',
    marginLeft: '15px',
    textDecoration: 'none',
  },
 
  logout: {
    boxShadow: 'none',
    backgroundColor: '#ff000000',
    color: 'black',
    '&:hover': {
      backgroundColor: '#ff000000',
      color: '#928f8f',
      boxShadow: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    mainContainer: {
      flexDirection: 'column-reverse',
    },
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    width: '250px',
    justifyContent: 'space-between',
  },
  adminName: {
    fontSize: '15px',
    color: 'black',
    marginRight: '25px',
  },

  leftContainer: {
    width: '40%',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    display:'flex'
  },

  


  brandContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent', // Clear background
    border: '1px solid #d1d1d1', // Subtle border
    borderRadius: '4px', // Slightly rounded corners
    padding: '6px 12px', // Adjusted padding
  },
  
  searchInput: {
    border: 'solid 1px #9d9d9d',
    flexDirection: 'row',

    height: '30px',
    marginRight: '8px',
    borderRadius: '12px', 
    backgroundColor: '#ffffff',
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px', 
      backgroundColor: '#ffffff',
      '&.Mui-focused': {
          border: 'none',
      },
    },
    '& .MuiOutlinedInput-input': {
      color: '#151515',
      fontSize: '14px',
      padding: '10px 0px', // Adjust padding to center the text
      fontFamily: '"Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important',
      
      // Make sure the height of the input field is enough to contain the text and the icon
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
  },
  

 
  searchIcon: {
    
    
    color: '#d1d1d1',
  // Icon color similar to border
  },
  searchButton: {
    backgroundColor: 'transparent', // Clear background
    color: '#0057FF', // Behance-like blue color for button text
    border: '1px solid #0057FF', // Matching border color
    borderRadius: '4px',
   
    
    textTransform: 'none',
    '&:hover': {
        backgroundColor: 'rgba(0, 87, 255, 0.04)', // Slight highlight on hover
        border: '1px solid #0057FF',
    },
  },
 
  searchBarOpen: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '0',
    border: 'none',

 
    overflow: 'hidden',
    animation: '$expand 0.5s forwards ease-out',
    '&:focus-within': {
        width: '100%', // Full width on focus
    },
},
iconButton: {
    padding: '10px 0px',
},
// Animation keyframes
'@keyframes expand': {
  '0%': {
      width: 0,
      padding: '0',
  },
  '100%': {
      width: '100%', // Adjust this to how wide you want the search bar to be
      padding: '0 10px', // Adjust padding if necessary
  },
},
navText :{
  fontFamily: '"Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important',
  fontWeight: 500,

},
button: {
  fontFamily: '"Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important',
  fontWeight: 500,
},

}));
